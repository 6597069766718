<style lang="scss" scoped>
.nav_d {
    padding-top: 42px;
    font-size: 30px;
    margin-bottom: 25px;
    display: flex;

}

.nav_d>div {
    margin-right: 68px;
    text-align: center;
    color: #848484;
    font-weight: bold;
}

.nav_d .active {
    color: #000;
}

.center_d {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.center_d>div {
    margin-bottom: 52px;
    width: calc(100% / 3 - 15px);


}

.msg {
    margin-top: 12px;

    .msg_L {
        min-width: 40px;
        background-color: #282A2D;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        margin-right: 16px;
        line-height: 1;

        .num {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
        }

        .num2 {
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            margin: 7px 0
        }

    }

    .border {
        width: 1px;
        height: 10px;
        background: #fff;
        margin: 7px 0
    }

}

.text1 {
    box-sizing: border-box;
    font-weight: bolder;
    padding-top: 42px;

    font-size: 32px;
    color: #000000;
}

.text2 {
    font-size: 16px;
    margin-top: 26px;

    color: #000000;
    line-height: 18px;
    margin-bottom: 36px;
}


.title1 {
    font-size: 20px;
    color: #000000;
    height: 101px;
    margin-bottom: 12px;
}

.title2 {
    color: #007BC0;
    font-size: 16px;
    margin-top: 26px;
    line-height: 18px;
    margin-bottom: 8px;
}

.title3 {
    font-size: 13px;
    color: #848484;
}

.ellipsis2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.body_center {
    padding-bottom: 119px;
}


/* 移动端 */
@media (max-width: 1200px) and (min-width: 300px) {
    .text1 {
        padding-top: 20px;
        font-size: 18px;
        line-height: 22px;
    }

    .text2 {
        font-size: 14px;
        margin-top: 8px;
        line-height: 18px;
        margin-bottom: 16px;
    }

    .body_center {
        padding-bottom: 0px;
    }

    .nav_d {
        padding-top: 16px;
        font-size: 18px;
        margin-bottom: 11px;
    }

    .nav_d>div {
        margin-right: 34px;
    }

    .center_d {
        box-sizing: border-box;
        padding: 11px 11px 0 11px;
    }

    .center_d>div {
        background: #fff;
        margin-bottom: 52px;
        width: calc(100% - 4.5px);
        margin-bottom: 24px;
        border-radius: 4px;

    }

    .title1 {
        height: 60px;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
        margin-top: 11px;
        margin-bottom: 6px;
        font-weight: bold;
    }

    .title2 {
        width: 108px;
        height: 32px;
        font-size: 14px;
    }

    .ellipsis2 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 40px;
    }

}

@media (max-width: 1200px) and (min-width: 750px) {
    .center_d>div {
        width: calc(50% - 4.5px);

    }
}
</style>
<template>
    <div class="page_d">

        <div class="container" v-if="list[0]">
            <!-- <div class="nav_d row12">
                <div :class="nav == 0 ? 'active' : ''" @click="setNav(0)"><span class="nav_li">All</span></div>
                <div :class="nav == 1 ? 'active' : ''" @click="setNav(1)"><span class="nav_li">News</span></div>
                <div :class="nav == 2 ? 'active' : ''" @click="setNav(2)"><span class="nav_li">Promotion</span></div>
            </div> -->
            <div class="text1">{{ listtitle[0]?.title }}</div>
            <div class="text2">{{ listtitle[0]?.content || $t("message.ThoEvontTeasorGroupOllors") }}</div>
            <!-- <div class="text2" v-html=" list[0].content "></div> -->
        </div>
        <div class="body_center">
            <div class="container ydShow_padding0">
                <div class="center_d row12 d-flex">
                    <div class="pointer" v-for="item in list" :key="item.questionInformationId" :index="index" @click="goMsg(item,index)">
                        <el-image class="pcShow" :src="item.thumbnailUrl" style="width: 100%; height: 217px;"
                            fit="cover"></el-image>
                        <img class="ydShow" :src="item.thumbnailUrl" alt="img">
                        <div class="msg d-flex">
                            <div class="msg_L" v-if="item.classify == 1">
                                <div>{{ item.createDate | formatDate_en_month }}</div>
                                <div class="num2">{{ item.createDate | formatDate_en_day }}</div>
                                <div>{{ item.createDate | formatDate_en_year }}</div>
                            </div>
                            <div class="msg_L" v-if="item.classify == 2">
                                <div>{{ item.activityStartTime | formatDate_en_month }}</div>
                                <div class="num">{{ item.activityStartTime | formatDate_en_day }}</div>
                                <div class="border"></div>
                                <div>{{ item.activityEndTime | formatDate_en_month }}</div>
                                <div class="num">{{ item.activityEndTime | formatDate_en_day }}</div>
                            </div>
                            <div class="msg_R">
                                <div class="title1 ellipsis3">{{ item.title }}</div>
                                <div class="title2 d-flex align-items-center">{{ $t("message.LearnMore") }}</div>
                            </div>
                        </div>

                    </div>
                    <div class="pcShow" v-if="list.length % 3 == 2"></div>

                </div>
            </div>
        </div>
        <div class="body_center">
            <div class="container ydShow_padding0">
                <div class="block pagination_d" v-if="recordsTotal">
                    <el-pagination small="true" @current-change="handleCurrentChange" :current-page.sync="page"
                        :page-size="pageSize" layout="prev, pager, next, jumper" :total="recordsTotal">
                    </el-pagination>
                </div>
                <div v-if="recordsTotal == 0" class="none_hint">{{ $t("message.NoDataAvailable") }}</div>
            </div>
        </div>

    </div>
</template>

<script>
import { EventBus } from "../main"
export default {

    components: {
    },
    name: "WhatsOn",
    props: {
        msg: String,
    },
    data() {
        return {
            nav: 0,
            listtitle: '',
            list: [],
            page: 1,
            pageSize: 6,
            recordsTotal: '',
        }
    },
    created() {
        this.getpage()
        this.gettitle()

    },
    methods: {
        handleCurrentChange(e) {
            console.log('handleCurrentChange==', e)
            this.page = e
            this.getpage()
        },
        setNav(num) {
            this.nav = num
            this.page = 1
            this.getpage()
        },
        goMsg(item, index) {
            console.log('item===', item)
            // var that = this
            // const href = that.$router.resolve({
            //     path: `/article`,
            //     query: {
            //         questionInformationId: item.questionInformationId
            //     }
            // });
            // window.open(href, "_blank");

            // var routeData = {
            //     name: 'article',
            //     query: { Id: item.questionInformationId }
            // }
            // const routeUrl = this.$router.resolve(routeData).href;
            // window.open(routeUrl, '_blank');
            if (EventBus) { //埋点
                var obj = {}
                obj.name = 'wsp_whatson_click'
                obj.params = {
                    Typy: 'Whats on page usage',
                    Name: "what's on news click",
                    page_num: index,
                    article_title: item.title,
                    article_id: item.questionInformationId,
                }
                EventBus.$emit('google-analytics', obj);
            }

            var that = this
            that.$router.push({
                name: "article",
                query: { Id: item.questionInformationId }
            }, () => { });

        },
        getpage() {
            var that = this
            that.$flyNo.request({
                method: 'POST',
                url: that.httpUrl.questionInformationpage,
                body: {
                    technicalEncyclopediaTypeId: that.nav.technicalEncyclopediaTypeId,
                    page: that.page,
                    pageSize: that.pageSize,
                    classify: that.nav,
                    isIdentity: 1
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.questionInformationpage, '_res==', res)
                that.list = res.resultData.pageInfo.rows
                that.recordsTotal = res.resultData.pageInfo.pageProps.totalCounts || 0
            })

        },
        gettitle() {
            var that = this
            that.$flyNo.request({
                method: 'POST',
                url: that.httpUrl.questionInformationpage,
                body: {
                    technicalEncyclopediaTypeId: that.nav.technicalEncyclopediaTypeId,
                    page: that.page,
                    pageSize: that.pageSize,
                    classify: 10,
                    isIdentity: 1
                }
            }).then(res => {
                if (!res) return false
                console.log('--------------——', that.httpUrl.questionInformationpage, '_res==', res)
                that.listtitle = res.resultData.pageInfo.rows
            })

        }
    }
}
</script>
